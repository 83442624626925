import { configureStore } from '@reduxjs/toolkit';
import settingsReducer from '../Slice/settingSlice';
import cartReducer from '../Slice/cartSlice';

const store = configureStore({
  reducer: {
    settings: settingsReducer,
    cart: cartReducer,
  },
});

export default store;
