import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUserData } from '../../../src/service/apiService';    

const initialState = {
  itemCount: 0,
};

export const userCartList = createAsyncThunk(
  'cart/userCartList',
  async (_, { getState }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }
      const userData = await fetchUserData(token);
      return userData.data.cart_count;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setItemCount: (state, action) => {
      state.itemCount = action.payload;
    },
    incrementItemCount: state => {
      state.itemCount += 1;
    },
    decrementItemCount: state => {
      state.itemCount -= 1;
    },
    resetItemCount: state => {
      state.itemCount = 0;
    },
  },
  extraReducers: builder => {
    builder.addCase(userCartList.fulfilled, (state, action) => {
      state.itemCount = action.payload;
    });
  },
});

export const { setItemCount, incrementItemCount, decrementItemCount, resetItemCount } = cartSlice.actions;

export default cartSlice.reducer;
