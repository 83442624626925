import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  settingsData: [], 
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettingsData(state, action) {
      state.settingsData = action.payload;
    
    },
    updateSetting(state, action) {
      const { keyName, newValue } = action.payload;
      const settingToUpdate = state.settingsData.find(setting => setting.key_name === keyName);
      if (settingToUpdate) {
        settingToUpdate.key_value = newValue;
      }
    },
  },
});

export const { setSettingsData, updateSetting } = settingsSlice.actions;

export default settingsSlice.reducer;
